export const GALLERIES = {
  first: [
    {
      image: 'https://panorama.quicket.io/demo/LH/photo/a350-900_allegris_img_0339_f.webp',
      thumb: 'https://panorama.quicket.io/demo/LH/photo/a350-900_allegris_img_0339_f_thumb.webp',
      text: 'Slide 1 description',
    },
    {
      image: '/gallery/f1.webp',
      thumb: '/gallery/f1.webp',
      panorama: 'https://viewer.quicket.io/?image=https://panorama.quicket.io/demo/LH/pano/LH_380-900_cabin1_1K_F.webp',
      text: 'Slide 2 description',
    },
    {
      image: 'https://panorama.quicket.io/demo/LH/photo/a350-900_allegris_img_0342_f.webp',
      thumb: 'https://panorama.quicket.io/demo/LH/photo/a350-900_allegris_img_0342_f_thumb.webp',
      text: 'Slide 3 description',
    },
    {
      image: '/gallery/f2.webp',
      thumb: '/gallery/f2.webp',
      panorama:
        'https://viewer.quicket.io/?image=https://panorama.quicket.io/demo/LH/pano/LH_380-900_cabin1_middle_F.webp',
      text: 'Slide 4 description',
    },
  ],
  business: [
    {
      image: '/gallery/b1.webp',
      thumb: '/gallery/b1.webp',
      panorama: 'https://viewer.quicket.io/?image=https://panorama.quicket.io/demo/LH/pano/LH_380-900_cabin2_7A_B.webp',
      text: 'Slide 1 description',
    },
    {
      image: 'https://panorama.quicket.io/demo/LH/photo/a350-900_allegris_img_0182_b.webp',
      thumb: 'https://panorama.quicket.io/demo/LH/photo/a350-900_allegris_img_0182_b_thumb.webp',
      text: 'Slide 2 description',
    },
    {
      image: '/gallery/b2.webp',
      thumb: '/gallery/b2.webp',
      panorama:
        'https://viewer.quicket.io/?image=https://panorama.quicket.io/demo/LH/pano/LH_380-900_cabin3_back_B.webp',
      text: 'Slide 3 description',
    },
    {
      image: 'https://panorama.quicket.io/demo/LH/photo/a350-900_allegris_img_0261_b.webp',
      thumb: 'https://panorama.quicket.io/demo/LH/photo/a350-900_allegris_img_0261_b_thumb.webp',
      text: 'Slide 4 description',
    },
  ],
  premium: [
    {
      image: 'https://panorama.quicket.io/demo/LH/photo/a350-900_allegris_img_0286_p.webp',
      thumb: 'https://panorama.quicket.io/demo/LH/photo/a350-900_allegris_img_0286_p_thumb.webp',
      text: 'Slide 1 description',
    },
    {
      image: '/gallery/p2.webp',
      thumb: '/gallery/p2.webp',
      panorama:
        'https://viewer.quicket.io/?image=https://panorama.quicket.io/demo/LH/pano/LH_380-900_cabin4_front_P.webp',
      text: 'Slide 2 description',
    },
    {
      image: 'https://panorama.quicket.io/demo/LH/photo/a350-900_allegris_img_0286_p.webp',
      thumb: 'https://panorama.quicket.io/demo/LH/photo/a350-900_allegris_img_0286_p_thumb.webp',
      text: 'Slide 3 description',
    },
    {
      image: '/gallery/p1.webp',
      thumb: '/gallery/p1.webp',
      panorama:
        'https://viewer.quicket.io/?image=https://panorama.quicket.io/demo/LH/pano/LH_380-900_cabin4_15H_P.webp',
      text: 'Slide 4 description',
    },
  ],
  econom: [
    {
      image: 'https://panorama.quicket.io/demo/LH/photo/a350-900_allegris_img_0307_e.webp',
      thumb: 'https://panorama.quicket.io/demo/LH/photo/a350-900_allegris_img_0307_e_thumb.webp',
      text: 'Slide 1 description',
    },
    {
      image: 'https://panorama.quicket.io/demo/LH/photo/a350-900_allegris_img_0319_e.webp',
      thumb: 'https://panorama.quicket.io/demo/LH/photo/a350-900_allegris_img_0319_e_thumb.webp',
      text: 'Slide 2 description',
    },
    {
      image: '/gallery/e1.webp',
      thumb: '/gallery/e1.webp',
      panorama:
        'https://viewer.quicket.io/?image=https://panorama.quicket.io/demo/LH/pano/LH_380-900_cabin5_front_E.webp',
      text: 'Slide 3 description',
    },
    {
      image: '/gallery/e2.webp',
      thumb: '/gallery/e2.webp',
      panorama:
        'https://viewer.quicket.io/?image=https://panorama.quicket.io/demo/LH/pano/LH_380-900_cabin5_26J_E.webp',
      text: 'Slide 4 description',
    },
  ],
};

export const FLIGHT_DATA_E = {
  id: '111',
  airlineCode: 'LH',
  flightNo: '458 ',
  departureDate: '2025-04-01',
  departure: 'MUC',
  arrival: 'SFO',
  cabinClass: 'E',
  planeCode: '',
};

export const FLIGHT_DATA_P = {
  id: '111',
  airlineCode: 'LH',
  flightNo: '458',
  departureDate: '2025-04-01',
  departure: 'MUC',
  arrival: 'SFO',
  cabinClass: 'P',
  planeCode: '',
};

export const FLIGHT_DATA_B = {
  id: '111',
  airlineCode: 'LH',
  flightNo: '458',
  departureDate: '2025-04-01',
  departure: 'MUC',
  arrival: 'SFO',
  cabinClass: 'B',
  planeCode: '',
};

export const FLIGHT_DATA_F = {
  id: '111',
  airlineCode: 'LH',
  flightNo: '458',
  departureDate: '2025-04-01',
  departure: 'MUC',
  arrival: 'SFO',
  cabinClass: 'F',
  planeCode: '',
};

export const PASSENGERS_DATA = [
  {
    id: '2',
    passengerLabel: 'John Doe',
    passengerColor: 'orange',
  },
];

export const CONFIG_DATA = {
  width: 400, // width of seatmap, height will be dynamic and depends on amount of decks/rows in decks
  // if `horizontal` flag is set to true - height and width will swap around (height being static)
  lang: 'EN',
  horizontal: false, // should seatmap be rendered horizontally or vertically
  rightToLeft: false, // changes tooltip text alignment (and decks placement) for RTL languages support
  visibleFuselage: false, // should nose and tail graphics be rendered
  visibleWings: false, // should position of wings be shown (helps to see how far/close they are from/to certain seats)
  visibleSeatPriceLabels: true,
  currencySign: '$',
  builtInDeckSelector: false, // if there's only one deck on the flight it doesn't do anything
  // if there's more it's possible to render all decks at once or only one with ability to switch them
  singleDeckMode: true, // if false, double-deck mode enabled - to show 2 decks at a time, without deck switcher

  builtInTooltip: true, // see `onTooltipRequested` section
  externalPassengerManagement: false, // see `onTooltipRequested` section
  tooltipOnHover: true, // see `onTooltipRequested` section

  apiUrl: 'https://sandbox.quicket.io/api/v1',
  apiAppId: 'aff6eb5e-1c83-4e5c-a2a2-seatmaps-com',
  apiKey: 'd5c55bd9-60f0-4e2f-84e0-seatmaps-com',
  // scaleType: 'zoom',

  colorTheme: {
    showSeatPrice: true,
    showSeatPriceOnHover: true,
    // most values are CSS-compatible
    deckLabelTitleColor: 'white',
    deckHeightSpacing: 0, // additional space on both ends of a deck (for aesthetics only)

    wingsWidth: 50,
    deckSeparation: 0,

    floorColor: '#fff', // color of decks floor
    seatLabelColor: 'white',
    seatStrokeColor: '#e7e7e7',
    seatStrokeWidth: 1,
    seatArmrestColor: '#ccc',
    notAvailableSeatsColor: '#f5f5f5', // fill color for seats that are not available, applied when seat `availability` has been set

    bulkBaseColor: '#ccc', // colors for bulks
    bulkCutColor: '#f5f5f5',
    bulkIconColor: '#ccc',

    defaultPassengerBadgeColor: 'darkred',
    fontFamily: 'Montserrat, sans-serif',

    tooltipBackgroundColor: 'rgb(255,255,255)',
    tooltipHeaderColor: '#4f6f8f',
    tooltipBorderColor: 'rgb(255,255,255)',
    tooltipFontColor: '#4f6f8f',
    tooltipIconColor: '#4f6f8f', // applied to measurements icons, feature icons have set colors
    tooltipIconBorderColor: '#4f6f8f',
    tooltipIconBackgroundColor: '#fff',
    tooltipSelectButtonTextColor: '#fff',
    tooltipSelectButtonBackgroundColor: 'rgb(42, 85, 128)',
    tooltipCancelButtonTextColor: '#fff',
    tooltipCancelButtonBackgroundColor: 'rgb(55, 55, 55)',

    deckSelectorStrokeColor: '#fff',
    deckSelectorFillColor: 'rgba(55, 55, 55, 0.5)',
    deckSelectorSize: 1,

    fuselageStrokeWidth: 10, // surrounds the whole plane including tail\nose if enabled (min = 10, max = 18)

    fuselageFillColor: 'white',
    fuselageStrokeColor: '#e7e7e7',

    fuselageWindowsColor: 'darkgrey',
    fuselageWingsColor: '#ccc',

    exitIconUrlLeft: '/l.svg', // URL to override built-in left exit icon, optional
    exitIconUrlRight: '/r.svg', // URL to override built-in right exit icon, optional
  },
};

export const AVAILABILITY_DATA = [
  {
    currency: 'USD',
    label: '1A',
    price: 1000,
    onlyForPassengerType: ['ADT', 'CHD', 'INF'],
    additionalProps: [
      {
        label: 'Test prop for all',
        icon: null,
      },
      {
        label: 'Another test prop for all',
        icon: 'wifi',
      },
    ],
  },
  {
    currency: 'USD',
    label: '1K',
    price: 1000,
    onlyForPassengerType: ['ADT', 'CHD', 'INF'],
    additionalProps: [
      {
        label: 'Test prop for all',
        icon: null,
      },
      {
        label: 'Another test prop for all',
        icon: 'wifi',
      },
    ],
  },
  {
    currency: 'USD',
    label: '1G',
    price: 0,
    onlyForPassengerType: ['ADT', 'CHD', 'INF'],
    additionalProps: [
      {
        label: 'Test prop for all',
        icon: null,
      },
      {
        label: 'Another test prop for all',
        icon: 'wifi',
      },
    ],
  },
  {
    currency: 'USD',
    label: '1H',
    price: 0,
    onlyForPassengerType: ['ADT', 'CHD', 'INF'],
    additionalProps: [
      {
        label: 'Test prop for all',
        icon: null,
      },
      {
        label: 'Another test prop for all',
        icon: 'wifi',
      },
    ],
  },
  {
    currency: 'USD',
    label: '3A',
    price: 0,
    onlyForPassengerType: ['ADT', 'CHD', 'INF'],
    additionalProps: [
      {
        label: 'Test prop for all',
        icon: null,
      },
      {
        label: 'Another test prop for all',
        icon: 'wifi',
      },
    ],
  },
  {
    currency: 'USD',
    label: '3C',
    price: 0,
    onlyForPassengerType: ['ADT', 'CHD', 'INF'],
    additionalProps: [
      {
        label: 'Test prop for all',
        icon: null,
      },
      {
        label: 'Another test prop for all',
        icon: 'wifi',
      },
    ],
  },
  {
    currency: 'USD',
    label: '3D',
    price: 0,
    onlyForPassengerType: ['ADT', 'CHD', 'INF'],
    additionalProps: [
      {
        label: 'Test prop for all',
        icon: null,
      },
      {
        label: 'Another test prop for all',
        icon: 'wifi',
      },
    ],
  },
  {
    currency: 'USD',
    label: '3G',
    price: 0,
    onlyForPassengerType: ['ADT', 'CHD', 'INF'],
    additionalProps: [
      {
        label: 'Test prop for all',
        icon: null,
      },
      {
        label: 'Another test prop for all',
        icon: 'wifi',
      },
    ],
  },
  {
    currency: 'USD',
    label: '3H',
    price: 0,
    onlyForPassengerType: ['ADT', 'CHD', 'INF'],
    additionalProps: [
      {
        label: 'Test prop for all',
        icon: null,
      },
      {
        label: 'Another test prop for all',
        icon: 'wifi',
      },
    ],
  },
  {
    currency: 'USD',
    label: '3K',
    price: 0,
    onlyForPassengerType: ['ADT', 'CHD', 'INF'],
    additionalProps: [
      {
        label: 'Test prop for all',
        icon: null,
      },
      {
        label: 'Another test prop for all',
        icon: 'wifi',
      },
    ],
  },
  {
    currency: 'USD',
    label: '4D',
    price: 0,
    onlyForPassengerType: ['ADT', 'CHD', 'INF'],
    additionalProps: [
      {
        label: 'Test prop for all',
        icon: null,
      },
      {
        label: 'Another test prop for all',
        icon: 'wifi',
      },
    ],
  },
  {
    currency: 'USD',
    label: '5G',
    price: 0,
    onlyForPassengerType: ['ADT', 'CHD', 'INF'],
    additionalProps: [
      {
        label: 'Test prop for all',
        icon: null,
      },
      {
        label: 'Another test prop for all',
        icon: 'wifi',
      },
    ],
  },
  {
    currency: 'USD',
    label: '6H',
    price: 0,
    onlyForPassengerType: ['ADT', 'CHD', 'INF'],
    additionalProps: [
      {
        label: 'Test prop for all',
        icon: null,
      },
      {
        label: 'Another test prop for all',
        icon: 'wifi',
      },
    ],
  },
  {
    currency: 'USD',
    label: '7A',
    price: 0,
    onlyForPassengerType: ['ADT', 'CHD', 'INF'],
    additionalProps: [
      {
        label: 'Test prop for all',
        icon: null,
      },
      {
        label: 'Another test prop for all',
        icon: 'wifi',
      },
    ],
  },
  {
    currency: 'USD',
    label: '7C',
    price: 0,
    onlyForPassengerType: ['ADT', 'CHD', 'INF'],
    additionalProps: [
      {
        label: 'Test prop for all',
        icon: null,
      },
      {
        label: 'Another test prop for all',
        icon: 'wifi',
      },
    ],
  },
  {
    currency: 'USD',
    label: '8D',
    price: 0,
    onlyForPassengerType: ['ADT', 'CHD', 'INF'],
    additionalProps: [
      {
        label: 'Test prop for all',
        icon: null,
      },
      {
        label: 'Another test prop for all',
        icon: 'wifi',
      },
    ],
  },
  // PREMIUM
  {
    currency: 'USD',
    label: '12A',
    price: 33,
    onlyForPassengerType: ['ADT', 'CHD', 'INF'],
    additionalProps: [
      {
        label: 'Test prop for all',
        icon: null,
      },
      {
        label: 'Another test prop for all',
        icon: 'wifi',
      },
    ],
  },
  {
    currency: 'USD',
    label: '14C',
    price: 33,
    onlyForPassengerType: ['ADT', 'CHD', 'INF'],
    additionalProps: [
      {
        label: 'Test prop for all',
        icon: null,
      },
      {
        label: 'Another test prop for all',
        icon: 'wifi',
      },
    ],
  },
  {
    currency: 'USD',
    label: '14D',
    price: 33,
    onlyForPassengerType: ['ADT', 'CHD', 'INF'],
    additionalProps: [
      {
        label: 'Test prop for all',
        icon: null,
      },
      {
        label: 'Another test prop for all',
        icon: 'wifi',
      },
    ],
  },
  {
    currency: 'USD',
    label: '14E',
    price: 33,
    onlyForPassengerType: ['ADT', 'CHD', 'INF'],
    additionalProps: [
      {
        label: 'Test prop for all',
        icon: null,
      },
      {
        label: 'Another test prop for all',
        icon: 'wifi',
      },
    ],
  },
  {
    currency: 'USD',
    label: '14G',
    price: 33,
    onlyForPassengerType: ['ADT', 'CHD', 'INF'],
    additionalProps: [
      {
        label: 'Test prop for all',
        icon: null,
      },
      {
        label: 'Another test prop for all',
        icon: 'wifi',
      },
    ],
  },
  {
    currency: 'USD',
    label: '15H',
    price: 33,
    onlyForPassengerType: ['ADT', 'CHD', 'INF'],
    additionalProps: [
      {
        label: 'Test prop for all',
        icon: null,
      },
      {
        label: 'Another test prop for all',
        icon: 'wifi',
      },
    ],
  },

  // ECONOM
  {
    currency: 'USD',
    label: '16J',
    price: 33,
    onlyForPassengerType: ['ADT', 'CHD', 'INF'],
    additionalProps: [
      {
        label: 'Test prop for all',
        icon: null,
      },
      {
        label: 'Another test prop for all',
        icon: 'wifi',
      },
    ],
  },
  {
    currency: 'USD',
    label: '18A',
    price: 33,
    onlyForPassengerType: ['ADT', 'CHD', 'INF'],
    additionalProps: [
      {
        label: 'Test prop for all',
        icon: null,
      },
      {
        label: 'Another test prop for all',
        icon: 'wifi',
      },
    ],
  },
  {
    currency: 'USD',
    label: '18B',
    price: 33,
    onlyForPassengerType: ['ADT', 'CHD', 'INF'],
    additionalProps: [
      {
        label: 'Test prop for all',
        icon: null,
      },
      {
        label: 'Another test prop for all',
        icon: 'wifi',
      },
    ],
  },
  {
    currency: 'USD',
    label: '18C',
    price: 33,
    onlyForPassengerType: ['ADT', 'CHD', 'INF'],
    additionalProps: [
      {
        label: 'Test prop for all',
        icon: null,
      },
      {
        label: 'Another test prop for all',
        icon: 'wifi',
      },
    ],
  },
  {
    currency: 'USD',
    label: '18D',
    price: 33,
    onlyForPassengerType: ['ADT', 'CHD', 'INF'],
    additionalProps: [
      {
        label: 'Test prop for all',
        icon: null,
      },
      {
        label: 'Another test prop for all',
        icon: 'wifi',
      },
    ],
  },
  {
    currency: 'USD',
    label: '18E',
    price: 33,
    onlyForPassengerType: ['ADT', 'CHD', 'INF'],
    additionalProps: [
      {
        label: 'Test prop for all',
        icon: null,
      },
      {
        label: 'Another test prop for all',
        icon: 'wifi',
      },
    ],
  },
  {
    currency: 'USD',
    label: '18F',
    price: 33,
    onlyForPassengerType: ['ADT', 'CHD', 'INF'],
    additionalProps: [
      {
        label: 'Test prop for all',
        icon: null,
      },
      {
        label: 'Another test prop for all',
        icon: 'wifi',
      },
    ],
  },
  {
    currency: 'USD',
    label: '18H',
    price: 33,
    onlyForPassengerType: ['ADT', 'CHD', 'INF'],
    additionalProps: [
      {
        label: 'Test prop for all',
        icon: null,
      },
      {
        label: 'Another test prop for all',
        icon: 'wifi',
      },
    ],
  },
  {
    currency: 'USD',
    label: '18J',
    price: 33,
    onlyForPassengerType: ['ADT', 'CHD', 'INF'],
    additionalProps: [
      {
        label: 'Test prop for all',
        icon: null,
      },
      {
        label: 'Another test prop for all',
        icon: 'wifi',
      },
    ],
  },
  {
    currency: 'USD',
    label: '18K',
    price: 33,
    onlyForPassengerType: ['ADT', 'CHD', 'INF'],
    additionalProps: [
      {
        label: 'Test prop for all',
        icon: null,
      },
      {
        label: 'Another test prop for all',
        icon: 'wifi',
      },
    ],
    color: 'tomato',
  },
  {
    currency: 'USD',
    label: '19A',
    price: 33,
    onlyForPassengerType: ['ADT', 'CHD', 'INF'],
    additionalProps: [
      {
        label: 'Test prop for all',
        icon: null,
      },
      {
        label: 'Another test prop for all',
        icon: 'wifi',
      },
    ],
  },
  {
    currency: 'USD',
    label: '19B',
    price: 33,
    onlyForPassengerType: ['ADT', 'CHD', 'INF'],
    additionalProps: [
      {
        label: 'Test prop for all',
        icon: null,
      },
      {
        label: 'Another test prop for all',
        icon: 'wifi',
      },
    ],
  },
  {
    currency: 'USD',
    label: '19C',
    price: 33,
    onlyForPassengerType: ['ADT', 'CHD', 'INF'],
    additionalProps: [
      {
        label: 'Test prop for all',
        icon: null,
      },
      {
        label: 'Another test prop for all',
        icon: 'wifi',
      },
    ],
  },
  {
    currency: 'USD',
    label: '19D',
    price: 33,
    onlyForPassengerType: ['ADT', 'CHD', 'INF'],
    additionalProps: [
      {
        label: 'Test prop for all',
        icon: null,
      },
      {
        label: 'Another test prop for all',
        icon: 'wifi',
      },
    ],
  },
  {
    currency: 'USD',
    label: '19E',
    price: 33,
    onlyForPassengerType: ['ADT', 'CHD', 'INF'],
    additionalProps: [
      {
        label: 'Test prop for all',
        icon: null,
      },
      {
        label: 'Another test prop for all',
        icon: 'wifi',
      },
    ],
  },
  {
    currency: 'USD',
    label: '19F',
    price: 33,
    onlyForPassengerType: ['ADT', 'CHD', 'INF'],
    additionalProps: [
      {
        label: 'Test prop for all',
        icon: null,
      },
      {
        label: 'Another test prop for all',
        icon: 'wifi',
      },
    ],
  },
  {
    currency: 'USD',
    label: '19H',
    price: 33,
    onlyForPassengerType: ['ADT', 'CHD', 'INF'],
    additionalProps: [
      {
        label: 'Test prop for all',
        icon: null,
      },
      {
        label: 'Another test prop for all',
        icon: 'wifi',
      },
    ],
  },
  {
    currency: 'USD',
    label: '19J',
    price: 33,
    onlyForPassengerType: ['ADT', 'CHD', 'INF'],
    additionalProps: [
      {
        label: 'Test prop for all',
        icon: null,
      },
      {
        label: 'Another test prop for all',
        icon: 'wifi',
      },
    ],
  },
  {
    currency: 'USD',
    label: '19K',
    price: 33,
    onlyForPassengerType: ['ADT', 'CHD', 'INF'],
    additionalProps: [
      {
        label: 'Test prop for all',
        icon: null,
      },
      {
        label: 'Another test prop for all',
        icon: 'wifi',
      },
    ],
  },

  {
    currency: 'USD',
    label: '20A',
    price: 33,
    onlyForPassengerType: ['ADT', 'CHD', 'INF'],
    additionalProps: [
      {
        label: 'Test prop for all',
        icon: null,
      },
      {
        label: 'Another test prop for all',
        icon: 'wifi',
      },
    ],
  },
  {
    currency: 'USD',
    label: '20E',
    price: 33,
    onlyForPassengerType: ['ADT', 'CHD', 'INF'],
    additionalProps: [
      {
        label: 'Clear air',
        icon: null,
      },
      {
        label: 'USB plug',
        icon: 'power',
      },
    ],
  },
  // 23
  {
    currency: 'USD',
    label: '23A',
    price: 33,
    onlyForPassengerType: ['ADT', 'CHD', 'INF'],
    additionalProps: [
      {
        label: 'Test prop for all',
        icon: null,
      },
      {
        label: 'Another test prop for all',
        icon: 'wifi',
      },
    ],
  },
  {
    currency: 'USD',
    label: '23G',
    price: 33,
    onlyForPassengerType: ['ADT', 'CHD', 'INF'],
    additionalProps: [
      {
        label: 'Test prop for all',
        icon: null,
      },
      {
        label: 'Another test prop for all',
        icon: 'wifi',
      },
    ],
  },
  {
    currency: 'USD',
    label: '23C',
    price: 33,
    onlyForPassengerType: ['ADT', 'CHD', 'INF'],
    additionalProps: [
      {
        label: 'Test prop for all',
        icon: null,
      },
      {
        label: 'Another test prop for all',
        icon: 'wifi',
      },
    ],
  },
  {
    currency: 'USD',
    label: '23D',
    price: 33,
    onlyForPassengerType: ['ADT', 'CHD', 'INF'],
    additionalProps: [
      {
        label: 'Test prop for all',
        icon: null,
      },
      {
        label: 'Another test prop for all',
        icon: 'wifi',
      },
    ],
  },
  {
    currency: 'USD',
    label: '23E',
    price: 33,
    onlyForPassengerType: ['ADT', 'CHD', 'INF'],
    additionalProps: [
      {
        label: 'Test prop for all',
        icon: null,
      },
      {
        label: 'Another test prop for all',
        icon: 'wifi',
      },
    ],
  },
  {
    currency: 'USD',
    label: '23F',
    price: 33,
    onlyForPassengerType: ['ADT', 'CHD', 'INF'],
    additionalProps: [
      {
        label: 'Test prop for all',
        icon: null,
      },
      {
        label: 'Another test prop for all',
        icon: 'wifi',
      },
    ],
  },
  {
    currency: 'USD',
    label: '23H',
    price: 33,
    onlyForPassengerType: ['ADT', 'CHD', 'INF'],
    additionalProps: [
      {
        label: 'Test prop for all',
        icon: null,
      },
      {
        label: 'Another test prop for all',
        icon: 'wifi',
      },
    ],
  },
  {
    currency: 'USD',
    label: '23J',
    price: 33,
    onlyForPassengerType: ['ADT', 'CHD', 'INF'],
    additionalProps: [
      {
        label: 'Test prop for all',
        icon: null,
      },
      {
        label: 'Another test prop for all',
        icon: 'wifi',
      },
    ],
  },
  {
    currency: 'USD',
    label: '23K',
    price: 33,
    onlyForPassengerType: ['ADT', 'CHD', 'INF'],
    additionalProps: [
      {
        label: 'Test prop for all',
        icon: null,
      },
      {
        label: 'Another test prop for all',
        icon: 'wifi',
      },
    ],
  },
  {
    currency: 'USD',
    label: '24A',
    onlyForPassengerType: ['ADT', 'CHD', 'INF'],
    additionalProps: [
      {
        label: 'Test prop for all',
        icon: null,
      },
      {
        label: 'Another test prop for all',
        icon: 'wifi',
      },
    ],
  },
  {
    currency: 'USD',
    label: '24B',
    onlyForPassengerType: ['ADT', 'CHD', 'INF'],
    additionalProps: [
      {
        label: 'Test prop for all',
        icon: null,
      },
      {
        label: 'Another test prop for all',
        icon: 'wifi',
      },
    ],
  },
  {
    currency: 'USD',
    label: '24C',
    onlyForPassengerType: ['ADT', 'CHD', 'INF'],
    additionalProps: [
      {
        label: 'Test prop for all',
        icon: null,
      },
      {
        label: 'Another test prop for all',
        icon: 'wifi',
      },
    ],
  },
  {
    currency: 'USD',
    label: '24D',
    onlyForPassengerType: ['ADT', 'CHD', 'INF'],
    additionalProps: [
      {
        label: 'Test prop for all',
        icon: null,
      },
      {
        label: 'Another test prop for all',
        icon: 'wifi',
      },
    ],
  },
  {
    currency: 'USD',
    label: '24E',
    onlyForPassengerType: ['ADT', 'CHD', 'INF'],
    additionalProps: [
      {
        label: 'Test prop for all',
        icon: null,
      },
      {
        label: 'Another test prop for all',
        icon: 'wifi',
      },
    ],
  },
  {
    currency: 'USD',
    label: '24F',
    onlyForPassengerType: ['ADT', 'CHD', 'INF'],
    additionalProps: [
      {
        label: 'Test prop for all',
        icon: null,
      },
      {
        label: 'Another test prop for all',
        icon: 'wifi',
      },
    ],
  },
  {
    currency: 'USD',
    label: '24H',
    onlyForPassengerType: ['ADT', 'CHD', 'INF'],
    additionalProps: [
      {
        label: 'Test prop for all',
        icon: null,
      },
      {
        label: 'Another test prop for all',
        icon: 'wifi',
      },
    ],
  },
  {
    currency: 'USD',
    label: '24J',
    onlyForPassengerType: ['ADT', 'CHD', 'INF'],
    additionalProps: [
      {
        label: 'Test prop for all',
        icon: null,
      },
      {
        label: 'Another test prop for all',
        icon: 'wifi',
      },
    ],
  },
  {
    currency: 'USD',
    label: '24K',
    onlyForPassengerType: ['ADT', 'CHD', 'INF'],
    additionalProps: [
      {
        label: 'Test prop for all',
        icon: null,
      },
      {
        label: 'Another test prop for all',
        icon: 'wifi',
      },
    ],
  },
  {
    currency: 'USD',
    label: '32A',
    onlyForPassengerType: ['ADT', 'CHD', 'INF'],
    additionalProps: [
      {
        label: 'Test prop for all',
        icon: null,
      },
      {
        label: 'Another test prop for all',
        icon: 'wifi',
      },
    ],
  },
  {
    currency: 'USD',
    label: '32B',
    onlyForPassengerType: ['ADT', 'CHD', 'INF'],
    additionalProps: [
      {
        label: 'Test prop for all',
        icon: null,
      },
      {
        label: 'Another test prop for all',
        icon: 'wifi',
      },
    ],
  },
  {
    currency: 'USD',
    label: '32C',
    onlyForPassengerType: ['ADT', 'CHD', 'INF'],
    additionalProps: [
      {
        label: 'Test prop for all',
        icon: null,
      },
      {
        label: 'Another test prop for all',
        icon: 'wifi',
      },
    ],
  },
  {
    currency: 'USD',
    label: '32D',
    onlyForPassengerType: ['ADT', 'CHD', 'INF'],
    additionalProps: [
      {
        label: 'Test prop for all',
        icon: null,
      },
      {
        label: 'Another test prop for all',
        icon: 'wifi',
      },
    ],
  },
  {
    currency: 'USD',
    label: '32E',
    onlyForPassengerType: ['ADT', 'CHD', 'INF'],
    additionalProps: [
      {
        label: 'Test prop for all',
        icon: null,
      },
      {
        label: 'Another test prop for all',
        icon: 'wifi',
      },
    ],
  },
  {
    currency: 'USD',
    label: '32F',
    onlyForPassengerType: ['ADT', 'CHD', 'INF'],
    additionalProps: [
      {
        label: 'Test prop for all',
        icon: null,
      },
      {
        label: 'Another test prop for all',
        icon: 'wifi',
      },
    ],
  },
  {
    currency: 'USD',
    label: '32H',
    onlyForPassengerType: ['ADT', 'CHD', 'INF'],
    additionalProps: [
      {
        label: 'Test prop for all',
        icon: null,
      },
      {
        label: 'Another test prop for all',
        icon: 'wifi',
      },
    ],
  },
  {
    currency: 'USD',
    label: '32J',
    onlyForPassengerType: ['ADT', 'CHD', 'INF'],
    additionalProps: [
      {
        label: 'Test prop for all',
        icon: null,
      },
      {
        label: 'Another test prop for all',
        icon: 'wifi',
      },
    ],
  },
  {
    currency: 'USD',
    label: '32K',
    onlyForPassengerType: ['ADT', 'CHD', 'INF'],
    additionalProps: [
      {
        label: 'Test prop for all',
        icon: null,
      },
      {
        label: 'Another test prop for all',
        icon: 'wifi',
      },
    ],
  },
  //
  {
    currency: 'USD',
    label: '20K',
    price: 33,
    onlyForPassengerType: ['ADT', 'CHD', 'INF'],
  },
  {
    currency: 'USD',
    label: '21F',
    price: 13,
    onlyForPassengerType: ['ADT', 'CHD', 'INF'],
  },
  {
    currency: 'USD',
    label: '21J',
    price: 13,
    onlyForPassengerType: ['CHD', 'INF'],
  },
  {
    currency: 'USD',
    label: '35K',
    price: 1337,
    onlyForPassengerType: ['CHD', 'INF'],
  },
  {
    currency: 'EUR',
    label: '70E',
    price: 1488,
  },
];
