import { useEffect, useState } from 'react';
import { GALLERIES, FLIGHT_DATA_E, FLIGHT_DATA_B, FLIGHT_DATA_P, PASSENGERS_DATA, FLIGHT_DATA_F } from './data';

import gsap from 'gsap';
import ScrollTrigger from 'gsap/dist/ScrollTrigger';

import TooltipGlobal from './components/TooltipGlobal';
import Panorama from './components/Panorama';
import Gallery from './components/Gallery';
import JetsSeatClass from './components/JetsSeatClass';
import SiteLayout from './components/SiteLayout';
import GalleryPopup from './components/GalleryPopup';

function App() {
  gsap.registerPlugin(ScrollTrigger);

  const [passengers, setPessangers] = useState([...PASSENGERS_DATA]);

  const [panoramaSrc, setPanoramaSrc] = useState(null);
  const [isPopupOpen, setPopupOpen] = useState(null);

  const [classCounter, setClassCounter] = useState(0);
  const [hoverGalleryCategory, setHoverGalleryCategory] = useState('');
  const [gallerySlideIndex, setGallerySlideIndex] = useState(0);
  const [seatData, setSeatData] = useState(null);
  const [selectedSeatData, setSelectedSeatData] = useState(null);

  const showPanorama = src => {
    if (src) {
      setPanoramaSrc(src);
    }
  };

  const hidePanorama = () => {
    setPanoramaSrc(null);
  };

  const showPopup = index => {
    setPopupOpen(true);
    setGallerySlideIndex(index);
    document.documentElement.classList.add('html--popup_open');
  };

  const hidePopup = () => {
    setPopupOpen(false);
    setPanoramaSrc(null);
    document.documentElement.classList.remove('html--popup_open');
  };

  const nextSeatClass = () => {
    if (classCounter < 3) {
      setClassCounter(classCounter + 1);
    }
  };

  const prevSeatClass = () => {
    if (classCounter > 0) {
      setClassCounter(classCounter - 1);
    }
  };

  const initStickySidebar = () => {
    setTimeout(() => {
      ScrollTrigger.create({
        trigger: '.js-seatmaps-sidebar',
        pin: true,
        start: 'top 0',
        onUpdate: data => {
          document.documentElement.style.setProperty('--pin-height', `${(data.end - data.start) * data.progress}px`);
        },
        end: () =>
          `+=${
            document.querySelector('.js-booking-col').clientHeight -
            document.querySelector('.js-seatmaps-sidebar').clientHeight -
            400
          }`,
      });
    }, 100);
  };

  const refreshPin = () => {
    ScrollTrigger.refresh();
  };

  const handleGalleryMouseOver = event => {
    if (event.target.classList.contains('jets-available')) {
      const businessHeight = document.querySelector('.js-seatmap-wrap[data-type="business"]')?.clientHeight || 0;
      const premiumHeight = document.querySelector('.js-seatmap-wrap[data-type="premium"]')?.clientHeight || 0;
      document.documentElement.style.setProperty('--premium-height', `${premiumHeight}px`);
      document.documentElement.style.setProperty('--business-height', `${businessHeight}px`);

      setHoverGalleryCategory(event.target.closest('.js-seatmap-wrap').dataset.type);
    }
  };

  const addGalleryHoverListener = () => {
    document.body.addEventListener('mouseover', handleGalleryMouseOver);
  };

  const clearActiveSeats = () => {
    if (passengers !== null) {
      setPessangers(null);
      setSeatData(null);
      setSelectedSeatData(null);
    }

    const resetSeat = [...PASSENGERS_DATA];
    delete resetSeat[0].seat;
    setPessangers(resetSeat);
  };

  useEffect(() => {
    document.documentElement.style.setProperty('--premium-height', '0px');
    document.documentElement.style.setProperty('--business-height', '0px');
    document.documentElement.style.setProperty('--pin-height', '0px');

    addGalleryHoverListener();
  }, []);

  return (
    <SiteLayout
      popup={
        <GalleryPopup
          isOpen={isPopupOpen}
          hidePopup={hidePopup}
          showPanorama={showPanorama}
          panoramaSrc={panoramaSrc}
          slides={GALLERIES[hoverGalleryCategory]}
          slideIndex={gallerySlideIndex}
        />
      }
      panorama={<Panorama src={panoramaSrc} handleClose={hidePanorama} />}
      gallery={<Gallery gallery={GALLERIES[hoverGalleryCategory]} showPopup={showPopup} />}
      tooltipGlobal={seatData || selectedSeatData ? <TooltipGlobal data={seatData || selectedSeatData} /> : null}
      nextSeatClass={nextSeatClass}
      prevSeatClass={prevSeatClass}
      classCounter={classCounter}
      selectedSeatData={selectedSeatData}
      seatData={seatData}
      clearActiveSeats={clearActiveSeats}
    >
      {classCounter >= 3 && (
        <JetsSeatClass
          seatData={seatData}
          flight={FLIGHT_DATA_F}
          passengers={passengers}
          type="first"
          title="First class"
          initStickySidebar={initStickySidebar}
          refreshPin={refreshPin}
          setSeatData={setSeatData}
          setSelectedSeatData={setSelectedSeatData}
        />
      )}
      {classCounter >= 2 && (
        <JetsSeatClass
          seatData={seatData}
          flight={FLIGHT_DATA_B}
          passengers={passengers}
          type="business"
          title="Business class"
          initStickySidebar={initStickySidebar}
          refreshPin={refreshPin}
          setSeatData={setSeatData}
          setSelectedSeatData={setSelectedSeatData}
        />
      )}
      {classCounter >= 1 && (
        <JetsSeatClass
          seatData={seatData}
          flight={FLIGHT_DATA_P}
          passengers={passengers}
          type="premium"
          title="Premium class"
          initStickySidebar={initStickySidebar}
          refreshPin={refreshPin}
          setSeatData={setSeatData}
          setSelectedSeatData={setSelectedSeatData}
        />
      )}
      <JetsSeatClass
        seatData={seatData}
        flight={FLIGHT_DATA_E}
        passengers={passengers}
        type="econom"
        title="Economy class"
        initStickySidebar={initStickySidebar}
        refreshPin={refreshPin}
        setSeatData={setSeatData}
        setSelectedSeatData={setSelectedSeatData}
      />
    </SiteLayout>
  );
}

export default App;
