import { JetsSeatMap } from '@seatmaps.com/react-lib';
import { AVAILABILITY_DATA, CONFIG_DATA } from '../../data';
import { JetsSeat } from '../Seat/JetsSeat';

const JetsSeatClass = ({
  title,
  initStickySidebar,
  refreshPin,
  seatData,
  setSelectedSeatData,
  setSeatData,
  flight,
  type,
  passengers,
}) => {
  const componentOverrides = {
    JetsSeat: JetsSeat,
  };

  return (
    <div class="seatmap_wrap_block js-seatmap-wrap" data-type={type}>
      <h3 class="seatmap__title">{title}</h3>
      <JetsSeatMap
        componentOverrides={componentOverrides}
        flight={flight}
        availability={AVAILABILITY_DATA}
        passengers={passengers}
        config={CONFIG_DATA}
        onSeatMapInited={() => {
          if (type === 'econom') {
            initStickySidebar();
          } else {
            refreshPin();
          }
        }}
        builtInTooltip={false}
        onSeatSelected={() => {
          setSelectedSeatData(seatData);
        }}
        onSeatUnselected={() => {
          setSelectedSeatData(null);
        }}
        onTooltipRequested={data => {
          setSeatData(data.seat);
        }}
        onSeatMouseLeave={() => {
          setSeatData(null);
        }}
      />
    </div>
  );
};

export default JetsSeatClass;
